import React, { useState } from "react";

export default function Tooltip({ children, icon }) {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <div
      style={{
        position: 'relative',
        marginBottom: '-6px',
      }}
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {icon}
      {isVisible && (
        <div
          style={{
            position: 'absolute',
            bottom: "30px",
            left: '-150px',
            // transform: 'translateX(-50%)',
            backgroundColor: 'white',
            color: 'black',
            padding: '12px',
            borderRadius: '8px',
            zIndex: 1000,
            fontWeight: '400',
            fontSize: '12px',
            boxShadow: '0px 12px 32px 0px #292B2F29',
            display: "block",
            width: "245px",
          }}
        >
          {children}
          <div
            style={{
              position: 'absolute',
              bottom: '-10px', // Position it slightly outside the tooltip
              left: '64%',
              transform: 'translateX(-50%)',
              width: '0',
              height: '0',
              borderLeft: '10px solid transparent',
              borderRight: '10px solid transparent',
              borderTop: '10px solid white', // Match the background color of the tooltip
              zIndex: 1000,
            }}
          />
        </div>
      )}
    </div>
  );
}
